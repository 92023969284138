import useFeatureToggle from '@/hooks/useFeatureToggle/useFeatureToggle';
import { useHasPermission } from 'admin-portal-shared-services';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  performs?: Array<Array<string>>;
  featureToggle?: string;
  fallback?: ReactNode;
}

type Return = JSX.Element | null;

const Can = ({ children, performs, featureToggle, fallback = null }: Props): Return => {
  const hasPermission = performs?.some((perform) => useHasPermission(perform)) ?? true;
  const isFeatureToggleEnabled = featureToggle ? useFeatureToggle(featureToggle) : true;

  if (!hasPermission || !isFeatureToggleEnabled) return fallback as Return;

  return children as Return;
};

export default Can;
