import MessageMap from '../i18n';

const es419: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  ItemServiceSection: {
    LoadingMessage: 'Loading',
  },
  sidemenu: {
    home: 'Página de inicio',
    list: 'Lista de vendedores',
    totalCoverage: 'Cobertura total',
  },
  homePage: {
    title: 'Gestión de punto de venta',
    list: {
      title: 'Lista de puntos de venta',
      description: 'Consultar toda la lista',
    },
    totalCoverage: {
      title: 'Cobertura total',
      description: 'Información sobre las áreas que los puntos de venta cubren actualmente.',
    },
  },
  sellerListPage: {
    title: 'Lista de vendedores',
  },
  toggleModal: {
    title: '¿Cambiar este vendedor a "{status}"?',
    text: 'El status del vendedor <{seller}> se mostrará como "{status}"',
    toast: {
      success: 'Vendedor ahora en {status}',
      error: 'Error al cambiar vendedor para {status}. Intentar otra vez.',
    },
    open: 'Abierto',
    close: 'Cerrado',
  },
  statusModal: {
    title: 'Cambiar el status para activar',
    text: 'Asignar un centro de distribución para tornar este vendedor en Activo.',
    selectPlaceholder: 'Seleccionar un centro de distribución',
    cancelTitle: '¿Estás seguro que deseas cancelar?',
    cancelText: 'El status de este vendedor permanecerá igual y no se cambiará a Activo. ',
    back: 'No, volver',
    quit: 'Sí, cancelar',
  },
  typeModal: {
    title: 'Activar vendedor',
    text: 'Ahora, asigna un tipo de vendedor a este vendedor. Esta acción es necesaria para cambiar su estado a "activo".',
    selectPlaceholder: 'Selecciona un tipo de vendedor',
    activate: 'Activar',
  },
  buttons: {
    goBack: 'Volver atrás',
    back: 'Volver',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    save: 'Guardar',
    apply: 'Aplicar',
    copy: 'Copiar',
    edit: 'Editar',
    select: 'Seleccionar',
    selectOrEdit: 'Seleccionar o editar',
    continue: 'Continue',
    setHours: 'Configurar',
    addHours: 'Agregar horario',
    next: 'Siguiente',
    setAreaDefault: 'Fijar área',
    editAreaDefault: 'Editar área',
    setCoverageAreaScheduled: 'Fijar o editar',
    enable: 'Habilitar',
    disable: 'Deshabilitar',
  },
  sellerAttributes: {
    displayName: 'Razón Social',
    legalName: 'Nombre legal',
    contacts: 'Contacts',
    phone: 'Teléfono',
    email: 'Email',
    mail: 'Email',
    billingAddress: 'Dirección de facturación',
    deliveryAddress: 'Dirección para envío',
    thoroughfare: 'Dirección',
    complement: 'Complemento',
    number: 'Número',
    neighborhood: 'Barrio',
    reference: 'Referencia',
    city: 'Ciudad',
    state: 'Estado',
    zipcode: 'Código postal',
    coordinates: 'Coordenadas',
    latitude: 'Latitud',
    longitude: 'Longitud',
    status: 'Status',
    online: 'Activo',
    sellerType: 'Tipo de vendedor',
    taxId: 'ID gubernamental',
    createdAt: 'Fecha de creación',
    updatedAt: 'Última actualización',
    fax: 'Fax',
    SELLER_TYPES: {
      RESTAURANT: 'Restaurante',
      BAR: 'Bar',
      WINE_HOUSE: 'Licorería',
      CONVENIENCE_STORE: 'Tienda de conveniencia',
      MARKET: 'Mercado',
      GROCERY_STORE: 'Tienda de comestibles',
      BAKERY: 'Panadería',
      DISK_DELIVERY: 'Disk delivery',
      PIT_STOP: 'Pit stop',
      CHOPP_BRAHMA_EXPRESS: 'Chopp Brahma Express',
      DARK_STORE_3RD_PARTY: 'Dark store (terceros)',
      OWNED_STORE: 'Tienda propia',
      OTHERS: 'Otros',
    },
  },
  deliveryCenter: {
    title: 'Centro de distribución ',
    empty:
      'No hay un centro de distribución asignado. Cambia el status del vendedor a Activo para asignarle uno. ',
    attributes: {
      id: 'ID',
      name: 'Nombre',
    },
  },
  sellerTable: {
    pagination: {
      quantityIndicator: 'en',
      pageSizeOptions: 'Elementos por página: {options}',
    },
    empty: {
      title: 'No se encontraron vendedores',
      message: 'Esta búsqueda no encontró ningún vendedor. Intenta una búsqueda diferente.',
    },
    loading: {
      title: 'Cargando',
      message: 'Estamos preparando la lista de vendedores para ti.',
    },
    filtered: {
      title: 'No hay vendedores aún',
      message: 'Los vendedores se mostrarán aquí.',
    },
    error: {
      400: {
        title: 'Ups, algo falló',
        message: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      401: {
        title: 'Ups, algo falló',
        message:
          'Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.',
      },
      403: {
        title: 'Ups, algo falló',
        message:
          'Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.',
      },
      404: {
        title: 'Ups, algo falló',
        message: 'No pudimos llevar a cabo esta acción. Por favor, vuelve a intentar.',
      },
      500: {
        title: 'Ups, algo falló',
        message: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      502: {
        title: 'Ups, algo falló',
        message: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      503: {
        title: 'Ups, algo falló',
        message: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
    },
  },
  sellerDetailsPage: {
    store: 'Tienda',
    open: 'Abierto',
    closed: 'Cerrado',
    generalInfo: 'Información General',
    editToast: {
      success: 'Información del vendedor modificada exitosamente.',
      error: 'Error en la información modificada del vendedor. Intentar otra vez.',
    },
    statusToast: {
      success: 'Status del vendedor modificado exitosamente.',
      error: 'Error al cambiar el status del vendedor. Intentar otra vez.',
    },
    deliveryCenterToast: {
      success: 'Centro de distribución asignado exitosamente.',
      error: 'Error al asignar el centro de distribución. Intentar otra vez.',
    },
    businessHoursMessage: 'Para activar a este vendedor, configura el horario de servicio.',
  },
  countries: {
    HN: 'Honduras',
    SV: 'El Salvador',
    BO: 'Bolivia',
    PA: 'Panamá',
    DO: 'República Dominicana',
    PY: 'Paraguay',
    PE: 'Perú',
    EC: 'Ecuador',
    ZA: 'Sudáfrica',
    CO: 'Colombia',
    MX: 'México',
    AR: 'Argentina',
    BR: 'Brasil',
  },
  filters: {
    placeholders: {
      country: 'Seleccione un país',
      deliveryCenter: 'Todos los centros de distribución',
      status: 'Seleccione un status',
    },
    status: {
      ALL: 'Todos los status',
      ACTIVE: 'Activo',
      PENDING: 'Pendiente',
      INACTIVE: 'Inactivo',
      REJECTED: 'Rechazado',
      BLOCKED: 'Bloqueado',
    },
  },
  fields: {
    optional: 'Opcional',
    required: {
      generic: 'Campo requerido',
      displayName: 'Ingresar la Razón Social',
      city: 'Ingresar el nombre de la ciudad',
      state: 'Ingresar el estado',
      zipcode: 'Ingresar código postal',
      latitute: 'Ingresar número de latitud',
      longitude: 'Ingresar número de longitud',
    },
    format: {
      generic: 'Formato inválido',
      latitute: 'El número de latitud deberá ser entre -90 y 90.',
      longitude: 'El número de longitud deberá ser entre -180 y 180.',
    },
  },
  unauthorized_page: {
    title: 'Ups, acceso denegado.',
    description: 'Parece que no tienes permiso para acceder a esta página.',
    action: 'Volver a la página de inicio',
  },
  notfound_page: {
    headerText: 'Mmm… Parece que esta página se ha ido',
    subHeaderText: 'Parece que esta página no se encontró o no existe.',
    buttonText: 'Volver a la página de inicio',
  },
  coverageArea: {
    title: 'Área de cobertura',
    selectOrEdit: 'Seleccionar o editar área de cobertura',
    helpText:
      'Para la ubicación del vendedor, consideramos las coordenadas informadas en la dirección de entrega',
    mapCaption: {
      title: 'Leyenda del mapa',
      store: 'Tienda',
    },
    createArea: {
      title: 'Crear un área',
      description:
        'Crea un área de cobertura dibujando un círculo y ajustando los puntos de edición',
      descriptionMultiPolygon:
        'Crea un área de cobertura dibujando un círculo y ajustando los puntos de edición, o seleccione una nueva área de cobertura activa',
      newAreaAction: 'Crea una nueva área',
      createOrSelectAnArea: 'Crear o seleccionar un área',
      selectAnActiveCoverageArea: 'Selecciona un área de cobertura activa',
    },
    editArea: {
      title: 'Editar área',
      description:
        'Cambia el área de cobertura editando su radio o moviendo sus puntos de edición.',
      nameInput: 'Nombre del área de cobertura',
      nameInputPlaceholder: 'Introduce nombre del área',
      namePlaceholder: 'Regular hours',
      nameError:
        'Ya tienes un área de cobertura con el mismo nombre. Ingresa uno diferente para continuar',
      editSelectedCoverageArea: 'Edita el área de cobertura seleccionada',
      editingActions: {
        title: 'Editar el área usando:',
        radius: 'Radio (km)',
        editPoints: 'Editar puntos',
        editPointsDescription:
          'Se restablecerá el radio la próxima vez que selecciones esta opción.',
        radiusDescription: 'Radio recomendado: 9 km.',
        deleteArea: 'Elimina el área',
      },
      deleteCoverageAreaWithAreaName: '¿Quieres borrar <{area}>?',
      deleteCoverageArea: 'Borrar el área de cobertura',
      selectCoverageArea:
        '¿Quieres que <{area}> se convierta en el área de cobertura activa para este vendedor?',
      changeActiveCoverageArea: 'Cambia el área de cobertura activa',
    },
    editAreaDialog: {
      title: 'Editar área de cobertura',
      titleSelectOrEdit: 'Selecciona o edita el área de cobertura',
      subtitle: 'Debe editar el área de cobertura para que el vendedor pueda activarse.',
    },
    toast: {
      success: 'Área de cobertura activa modificada correctamente.',
      error: 'No hemos podido cambiar el área de cobertura activa. Vuelve a intentarlo',
    },
  },
  storeStatus: {
    ACTIVE: 'Activo',
    PENDING: 'Pendiente',
    INACTIVE: 'Inactivo',
    REJECTED: 'Rechazado',
    BLOCKED: 'Bloqueado',
  },
  defaultCoverageArea: {
    title: 'Área de cobertura predeterminada',
    helpText:
      'Essa é a área de entrega principal. Se activará cuando no haya programaciones activas',
    helpTextModal:
      'Essa é a área de entrega principal. Se activará cuando no haya programaciones activas',
    form: {
      titleCreate: 'Crear un área de cobertura',
      titleEdit: 'Editar un área de cobertura',
      description: 'Crea un área de cobertura dibujando un círculo y moviendo para editar.',
      placeholderName: 'Area predeterminada',
      labelName: 'Nombre del área',
      typeCoordinatesLabel: 'Editar el área usando',
      buttonSave: 'Guardar',
      butttonCancel: 'Volver',
    },
    toast: {
      successCreate: 'Área de cobertura creada con éxito.',
      successEdit: 'Área de cobertura editado con éxito.',
      error: 'No fue posible completar la acción. Inténtalo de nuevo',
    },
    confirmDefaultCoverageAreaDialog: {
      title: 'Crear área de cobertura predeterminada',
      subtitle: 'Debes establecer el área de cobertura predeterminada para activar la tienda.',
      buttonConfirm: 'Crear area',
      buttonBack: 'Back',
    },
  },
  scheduledCoverageArea: {
    title: 'Área de cobertura programada',
    titleModal: 'Área de cobertura programada',
    helpText:
      'Define una área de entrega temporal para días y horas específicos. Cuando está inactiva, las entregas se envían a la área principal.',
    helpTextModal:
      'Define una área de entrega temporal para días y horas específicos. Cuando está inactiva, las entregas se envían a la área principal.',
    emptyContent: 'Este punto de venta aún no tiene áreas programadas.',
    filledContent: 'Crea nuevas áreas con horarios o edita las existentes.',
    form: {
      titleCreate: 'Crear un área de cobertura',
      titleEdit: 'Crear un área de cobertura',
      description: 'Crea un área de cobertura dibujando un círculo y moviendo para editar.',
      labelName: 'Nombre del área (obligatorio)',
      placeholderName: 'Ej: horario nocturno',
      helperTextName: 'El nombre del área no puede modificarse posteriormente.',
      typeCoordinatesLabel: 'Editar el área usando',
      buttonSave: 'Guardar',
      butttonCancel: 'Volver',
      buttonRemove: 'Eliminar área',
      schedulingLabel: 'Programación (obligatoria)',
      schedulingText: 'Gestionar la programación',
      errors: {
        name: 'El nombre ya ha sido utilizado en otra área. Por favor, ingrese uno diferente',
      },
    },
    scheduling: {
      title: 'Programación',
      subtitle: 'Ajustes',
      infoText: 'Elige el día y la hora de inicio y fin para programar el área.',
      addText: '+ Añadir nueva fecha y hora',
      dayLabel: 'Día de la semana',
      startAtLabel: 'Hora inicial',
      endAtLabel: 'Hora final',
      errors: {
        alreadySelected: 'El intervalo de tiempo ya está en otra área de cobertura.',
      },
    },
    toast: {
      successCreate: 'Área de cobertura creada con éxito.',
      successEdit: 'Área de cobertura editado con éxito.',
      successRemove: 'Área de cobertura eliminada con éxito.',
      error: 'No fue posible completar la acción. Inténtalo de nuevo',
    },
    manageArea: {
      title: 'Crear un área de cobertura',
      description: 'Crea un área de cobertura dibujando un círculo y moviendo para editar.',
      buttonCreateNewArea: 'Crear nueva área',
      editAreaLabel: 'Elige un área para editar',
      editSelectedAreaButtonLabel: 'Editar la zona de cobertura seleccionada',
      daysAndHoursSelectedAreaCard: {
        title: 'Días y horas en que se activará el área',
        textBetweenStartAtAndFinishAt: 'a',
      },
    },
    toggle: {
      text: 'Crea nuevas áreas con horarios o edita las existentes.',
      enable: {
        title: '¿Estás seguro de que quieres habilitar el área programada?',
        firstText: 'Al habilitar esta funcionalidad, el área se indicará con la etiqueta',
        secondText: 'cuando llegue la hora programada.',
      },
      disable: {
        title: '¿Estás seguro de que quieres deshabilitar el área programada?',
        text: 'Al deshabilitar esta funcionalidad, las áreas programadas no se activarán automáticamente cuando comience el tiempo programado.',
      },
    },
    timezoneInfo: {
      nameTitle: 'Esta tienda sigue',
      nameValue: 'Time Zone:',
      currentTime: 'Hora actual',
    },
  },
  weekDays: {
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
  },
  businessHours: {
    title: 'Horario de servicio',
    edit: 'Editar horario de servicio',
    empty:
      'No se ha establecido el horario de servicio. Esta acción es necesaria para activar a este vendedor.',
    opensAt: 'Abre a las',
    closesAt: 'Cierra a las',
    toast: {
      success: 'Horario de servicio actualizado.',
      error: 'No se pudo actualizar el horario de servicio. Intenta de nuevo.',
    },
  },
  totalCoveragePage: {
    title: 'Cobertura total',
    filters: {
      filteredBy: 'Filtrado por',
      country: 'País',
      filterButton: 'Filtro',
      searchPlaceholder: 'Buscar dirección',
      searchButton: 'Buscar',
      buttonApplyFilter: 'Aplicar',
      buttonCancel: 'Cancelar',
      operationStatus: 'Estado actual',
      deliveryCenters: 'Centros de entrega',
      AlldeliveryCenters: 'Todos los centros de distribución',
    },
    updateMapButton: 'Actualizar mapa',
    mapLoading: {
      title: 'Cargando',
      text: 'Estamos preparando el mapa para ti.',
    },
    operationStatus: {
      CLOSED: 'Cerrado',
      OPEN: 'Abierto',
      SHOULD_BE_OPEN: 'Debería estar abierto',
    },
    lastUpdated: 'Última actualización:',
    toastErrorLoading: 'No se pudo cargar tu solicitud. Inténtalo de nuevo.',
    sellerDetails: {
      phone: 'Número de teléfono',
      buttonGoToStore: 'Mostrar más',
      businessHours: 'Horario comercial',
      businessHoursNotProvided: 'Horario comercial no proporcionado',
    },
    sellerList: {
      numberSellers: 'Número de puntos de venta en esta área:',
      showMore: '(Mostrar más)',
    },
  },
};

export default es419;
